import React, { useMemo, useRef, useState, useCallback } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { elementHeightsPerRow } from './elementHeightsPerRow';

interface DynamicElementContextProps {
  registerRef: (name: string, planId: string, oldRef: any) => React.RefCallback<any>;
  elementHeightsByName: Record<ElementName, Map<PlanId, number>>;
}

const DynamicElementContext = React.createContext<DynamicElementContextProps>(null!);

interface DynamicElementProviderProps {
  elements: { name: string; minHeight: number }[];
}

type PlanId = string;
type ElementName = string;

export const DynamicElementProvider: React.FC<DynamicElementProviderProps> = ({ children, elements }) => {
  const { isSSR } = useEnvironment();

  const elementRefs = useRef<Record<ElementName, Map<PlanId, HTMLSpanElement>>>(
    Object.fromEntries(elements.map((el) => [el.name, new Map()])),
  );

  const [elementHeightsByName, setElementHeightsByName] = useState<Record<ElementName, Map<PlanId, number>>>({});

  const updateElementHeights = useCallback(() => {
    setElementHeightsByName(elementHeightsPerRow(elementRefs.current, elements));
  }, []);

  // isSSR is definitely not going to change during run-time.
  const observer = useMemo(
    () => (isSSR || !window.ResizeObserver ? null : new ResizeObserver(updateElementHeights)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const observeElementSizeChanges = useCallback(
    (name: string, planId: string, el: HTMLSpanElement) => {
      if (!elementRefs.current[name].has(planId)) {
        elementRefs.current[name].set(planId, el);
        observer?.observe(el);
      }
    },
    [observer],
  );

  const unobserveElementSizeChanges = useCallback(
    (name: string, id: string, el: HTMLSpanElement) => {
      observer?.unobserve(el);
      elementRefs.current[name].delete(id);
      updateElementHeights();
    },
    [observer, updateElementHeights],
  );

  const registerRef = React.useCallback(
    (elementName: string, planId: string, oldRef: any): React.RefCallback<any> => (node) => {
      if (node && oldRef.current !== node) {
        observeElementSizeChanges(elementName, planId, node);
      }
      if (oldRef.current) {
        unobserveElementSizeChanges(elementName, planId, oldRef.current);
      }
      oldRef.current = node;
    },
    [observeElementSizeChanges, unobserveElementSizeChanges],
  );

  const context = useMemo(() => ({ elementHeightsByName, registerRef }), [elementHeightsByName, registerRef]);

  return <DynamicElementContext.Provider value={context}>{children}</DynamicElementContext.Provider>;
};

export const useDynamicElementContext = () => {
  const context = React.useContext(DynamicElementContext);
  if (!context) {
    throw new Error('useDynamicElement must be used within a DynamicElementProvider');
  }
  return context;
};

export const useDynamicElement = (name: string) => {
  const { registerRef, elementHeightsByName } = useDynamicElementContext();
  const oldRef = React.useRef<any>(null);

  return {
    registerRef: React.useCallback((planId: string) => registerRef(name, planId, oldRef), [registerRef, name]),
    heightByPlanId: React.useMemo(() => elementHeightsByName[name] ?? new Map(), [elementHeightsByName, name]),
  };
};
