import React from 'react';
import { ISettingsColor, StyleParamType } from '@wix/tpa-settings';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, IStyle } from '@wix/yoshi-flow-editor';
import { useStylesParams } from '../../hooks';
import { fontSettingToVars, textPresetsToVars } from '../../utils/cssVars';

interface Props {
  id: string;
  style: IStyle;
}

const CssVars: React.FC<Props> = React.memo(({ id, style }) => {
  const styles = useStyles();
  const { isEditor } = useEnvironment();
  const styleParams = useStylesParams();

  const customStyleParamsProperties = Object.values(styleParams).reduce((currentCustomProperties, styleParam) => {
    let nextCustomProperty: string = '';

    switch (styleParam.type) {
      case StyleParamType.Font: {
        const styleParamValue = styles.get(styleParam);
        const { value, preset, fontStyleParam } = styleParamValue;

        if (fontStyleParam) {
          if (preset === 'Custom') {
            const fontValue = value.split(';')[0].split(':')[1];
            nextCustomProperty += `--${styleParam.key}: ${fontValue};\n`;
            if (value.includes('text-decoration')) {
              nextCustomProperty += `--${styleParam.key}-text-decoration: underline;`;
            }
          } else {
            nextCustomProperty += `--${styleParam.key}: var(--${preset});\n`;
          }
        } else {
          nextCustomProperty += cssVarsToString(fontSettingToVars(styleParam.key!, styleParamValue));
        }
        break;
      }
      case StyleParamType.Color: {
        nextCustomProperty += cssVarsToString(colorToVars(styleParam.key!, styles.get(styleParam)));
        break;
      }
      case StyleParamType.Boolean:
        nextCustomProperty += `--${styleParam.key}: ${styles.get(styleParam) ? 'visible' : 'hidden'};`;
        break;
      case StyleParamType.Number:
        nextCustomProperty += `--${styleParam.key}: ${styles.get(styleParam)};`;
        break;
    }

    return `${currentCustomProperties}\n${nextCustomProperty}`;
  }, '');

  const customTextPresetProperties = cssVarsToString(textPresetsToVars(style.siteTextPresets));

  const customColorProperties = style.siteColors
    .filter(({ reference }) => reference.startsWith('color'))
    .map(({ reference, value }) => `--wix-${reference}: ${hexToTripplet(value)};`)
    .join('\n');

  const selector = `${isEditor ? '.' : '.'}${id}`;
  return (
    <style type="text/css">
      {`${selector} {
			${customStyleParamsProperties}
			${customTextPresetProperties}
			${customColorProperties}
		}`}
    </style>
  );
});

export default CssVars;

const hexToTripplet = (hex: string): `${string}, ${string}, ${string}` => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
};

const cssVarsToString = (vars: Record<string, string | number | undefined | null>) =>
  Object.entries(vars).reduce((acc, [name, value]) => (value ? acc + `${name}: ${value}; ` : acc), '');

const colorToVars = (name: string, { value, opacity }: ISettingsColor) => {
  const [r, g, b, a] = value.match(/[\d.]+/g) ?? [];
  return {
    [`--${name}`]: [r, g, b, a ?? 1].toString(),
    [`--${name}-rgb`]: [r, g, b].toString(),
    [`--${name}-opacity`]: opacity,
  };
};
