function normalizeHeight(height: number, minHeight: number) {
  if (typeof height === 'number' && isFinite(height)) {
    return Math.max(minHeight, height);
  }
  return minHeight;
}

type PlanId = string;
type ElementType = string;

export function elementHeightsPerRow(
  elementRefsMap: Record<ElementType, Map<PlanId, HTMLSpanElement>>,
  elements: { name: string; minHeight: number }[],
): Record<ElementType, Map<PlanId, number>> {
  const currentHeights: Record<ElementType, Map<PlanId, number>> = Object.fromEntries(
    elements.map((o) => [o.name, new Map()]),
  );

  const finalHeights: Record<ElementType, Map<PlanId, number>> = Object.fromEntries(
    elements.map((o) => [o.name, new Map()]),
  );

  elements.forEach(({ name, minHeight }) => {
    const rows: Map<number, { maxHeight: number; planIds: PlanId[] }> = new Map();
    elementRefsMap[name].forEach((ref, planId) => {
      const { top, height } = ref.getBoundingClientRect();
      currentHeights[name].set(planId, height);

      const { maxHeight, planIds } = rows.get(top) ?? { maxHeight: 0, planIds: [] };
      rows.set(top, {
        maxHeight: Math.max(maxHeight, normalizeHeight(height, minHeight)),
        planIds: [planId, ...planIds],
      });
    });

    rows.forEach(({ maxHeight, planIds }) => {
      planIds.forEach((id) => finalHeights[name].set(id, maxHeight));
    });
  });

  return finalHeights;
}
