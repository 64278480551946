import { ISettingsFont } from '@wix/tpa-settings';
import { IStyle, ITextPreset } from '@wix/yoshi-flow-editor';

export function textPresetToVars(presetName: string, preset: ITextPreset) {
  const value = preset.value.replace(/(^font:|;$)/gm, '');
  const prefix = '--wix-font-' + presetName;
  return {
    [prefix]: value,
    [prefix + '-style']: preset.style,
    [prefix + '-variant']: value.split(' ')[1],
    [prefix + '-weight']: preset.weight,
    [prefix + '-size']: preset.size,
    [prefix + '-line-height']: preset.lineHeight,
    [prefix + '-family']: preset.fontFamily,
    [prefix + '-text-decoration']: 'none',
  };
}

export function textPresetsToVars(textPresets: IStyle['siteTextPresets']) {
  const variables = Object.entries(textPresets).map(([name, preset]) => textPresetToVars(name, preset));
  return Object.assign({}, ...variables);
}

export function fontSettingToVars(name: string, { family, style, size }: ISettingsFont) {
  const prefix = '--' + name;
  const fontWeight = style?.bold ? 'bold' : 'normal';
  const fontSize = (size ?? '17') + 'px';
  const lineHeight = '1.4em';
  const fontStyle = style?.italic ? 'italic' : 'normal';
  const fontValue = `${fontStyle} ${fontWeight} ${fontSize}/${lineHeight} ${family}`;
  return {
    [prefix]: fontValue,
    [prefix + '-style']: fontStyle,
    [prefix + '-variant']: 'normal',
    [prefix + '-weight']: fontWeight,
    [prefix + '-size']: fontSize,
    [prefix + '-line-height']: lineHeight,
    [prefix + '-family']: family,
    [prefix + '-text-decoration']: style?.underline ? 'underline' : 'none',
  };
}
